<template>
  <div>
    <el-card style="margin-bottom: 20px" class="top_card">
      <img :src="topDetails.headPortrait" alt="" class="left" />
      <div class="right">
        <div class="right_top">
          <div class="activityName" style="display:flex;">
            <div>{{ topDetails.name }}</div>
            <div style="margin-left:10px">
              <span style="color:#F89B45">  ¥{{topDetails.consultingFee }}</span>
            
            <span style="color:#BFBFBF;font-size: 12px;">一个小时</span>
            </div>
          </div>
          <div class="operation_area">
            <el-button type="primary" @click="addinSertSelectedCompany">加入已选</el-button>
            <!-- <el-button type="info" plain style="color: #4e93fb">设置关联度</el-button> -->
          </div>
        </div>
        <div class="label_list">
          <span v-for="(label, index) in topDetails.tagsList" :key="index">#{{ label }}</span>
        </div>
        <div class="synopsis_box">
          <div class="synopsis">简介：</div>
          <div class="synopsis_content">
           
            <span v-html="showactivityIntroL"></span>
            <span
              class="more"
              @click="checkActivityIntro"
              v-if="this.topDetails.activityIntro && this.topDetails.activityIntro.length > 60"
            >
              {{ showactivityIntroLBol ? '收起' : '更多' }}
            </span>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="bottom_card">
      <div class="relevance_box">
        <div class="relevance">
          关联度： 
          <el-radio-group v-model="queryInfo.correlation" @change="search">
            <el-radio label="">全部</el-radio>
            <el-radio label="90">只选90%以上</el-radio>
          <el-radio  label="80">只选80%以上</el-radio>
          <el-radio  label="70">只选70%以上</el-radio> 
            </el-radio-group>
          
        </div>
        <div class="right">
          <img src="../../../../static/images/jr-icon-volume-copy.png" alt="" />
          <span>最新刷新时间：<span style="color: #333333"></span></span>
        </div>
      </div>
      <div class="seach_box">
        <el-input
          placeholder="请输入企业名称"
          v-model="queryInfo.queryConditions"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
        <!-- <el-checkbox v-model="checked" style="margin-left: 10px" @change="checkGeneralVersion"
          >全选所有智参企业</el-checkbox
        > -->
      </div>
      <el-table
      :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        v-loading="loading"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="changeSort"
      >
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column label="编号" width="120" align="center" prop="number"> </el-table-column>

        <el-table-column prop="companyLogo" label="logo" width="200" align="center" show-overflow-tooltip>
          <template v-slot="{ row }">
            <el-image
            v-if="row.companyLogo"
                slot="reference"
                :src="row.companyLogo"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              <el-image
                v-else
                slot="reference"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyFullName"
          label="企业名称"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column prop="industry" label="所属行业" align="center" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column prop="createTime" label="所在城市" align="center" width="100">
          <template slot-scope="{ row }">
            {{ row.province }}
            {{ row.city }}
          </template>
        </el-table-column>

        <el-table-column prop="labelList" label="标签" align="center">
          <template v-slot="{ row }">
            <span v-for="(o, index) in row.labelList" :key="index">
              <span v-if="index < 4 && o != ''" class="office">#{{ o }}</span>
            </span>
            <el-popover placement="bottom" width="230" trigger="hover">
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  v-for="(o, index) in row.labelList && row.labelList.length
                    ? row.labelList.filter((el, index) => index > 3)
                    : []"
                  :key="index"
                  style="
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #4e93fb;
                    margin-right: 10px;
                  "
                  >#{{ o }}</span
                >
              </div>
              <span
                style="color: #4e93fb"
                slot="reference"
                v-if="row.labelList && row.labelList.length > 4"
                >...{{ row.labelList.length - 4 }}</span
              >
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="关联度" prop="correlation" sortable align="center" show-overflow-tooltip>
          <template slot-scope="scope">
                <div class="correlationBox">
                  <div class="correlation">{{ scope.row.correlation }}%</div>
                </div>
              </template>
        </el-table-column>

        <el-table-column prop="enterpriseIntro" label="企业简介" align="center">
          <template v-slot="{ row }">
            <el-popover placement="bottom" width="230" trigger="hover">
              <div>
                <span>{{ row.enterpriseIntro }}</span>
              </div>
              <div
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                "
                slot="reference"
              >
                {{ row.enterpriseIntro }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
       
      </el-table>
      <div class="block">
        <el-pagination
          style="margin-top: 20px; text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  selectMatching,
  getById,
  insertSelectedCompany,
  artificialSelectMatching
} from '../../../api/expert.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      checked: false,
      radio: null,
      showactivityIntroL: '',
      showactivityIntroLBol: false,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        activityId: this.activityId,
        orderByParam: '',
        queryConditions: null
      },
      topDetails: {},
      tableData: [],
      companyIds: [],
      total: 0,
      loading:false
    }
  },
  props: {
    activityId: {
      type:String
    },
    type:{
      type:String
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  created() {
    this.search()
    // this.loading = true
    getById({  id: this.activityId }).then((res) => {
      if (res.data.resultCode == 200) {
        console.log(res.data.data.list, 'dfsd')
        this.topDetails = res.data.data
        this.showactivityIntroL = this.topDetails.specialistIntro.slice(0, 60) + '...'
      }
    })
  },

  mounted() {},
  //方法集合
  methods: {
    checkActivityIntro() {
      this.showactivityIntroLBol = !this.showactivityIntroLBol

      if (this.showactivityIntroLBol) {
        this.showactivityIntroL = this.topDetails.activityIntro
      } else {
        this.showactivityIntroL = this.topDetails.activityIntro.slice(0, 60) + '...'
      }
    },
    // 点击头部table栏
    clickItem(item) {
      this.headTabActive = item.id
      // console.log(item.id)
    },
    search() {
      this.loading=true
      if(this.type==1){
        
        artificialSelectMatching(this.queryInfo).then(({ data: res }) => {
        this.tableData = res.data.list
        if (this.tableData.length != 0) {
          this.$refs.multipleTable.toggleAllSelection()
        }
        this.loading=false
        this.total = res.data.total
        
      })
      }else {
        selectMatching(this.queryInfo).then(({ data: res }) => {
        this.tableData = res.data.list
        if (this.tableData.length != 0) {
          this.$refs.multipleTable.toggleAllSelection()
        }
        this.loading=false
        this.total = res.data.total
        
      })
      }
   
    },
    checkGeneralVersion(bol) {
      if (bol) {
        this.queryInfo.generalVersion = 0
      } else {
        this.queryInfo.generalVersion = ''
      }
      this.search()
    },
    async addinSertSelectedCompany() {
      if (!this.companyIds.length) {
        return this.$message.warning('请选择企业')
      }
      const { data: res } = await insertSelectedCompany({
        id: this.activityId,
        companyIds: this.companyIds
      })
      if (res.resultCode === 200) {
        this.search()
        this.$message.success('加入成功')
        this.$emit('getHeaderNum')
      } else {
        this.$message.error(res.message)
      }
    },

  
  //排序
  changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'correlation') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'm.correlation asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'm.correlation desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'm.correlation desc'
          this.search()
        }
      }
    },
    // table的checkbox状态改变时触发
    handleSelectionChange(val) {
      this.companyIds = val.map((el) => el.id)
    },
    // 切换单页展示数据数量
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.search()
    },
    // 切换分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.queryInfo.pageNum = val
      this.search()
    }
  }
}
</script>
<style lang="less" scoped>
  .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }
.top_card {
  ::v-deep .el-card__body {
    // padding: 100px;
    display: flex;
  }
  
  // display: flex;
  .left {
    width: 179.85px;
    height: 179.88px;
    flex-shrink: 0;
    margin-right: 15px;
  }
  .right {
    flex: 1;
    .right_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .activityName {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;

        color: #333333;
      }
    }
    .label_list {
      span {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4e93fb;
      }
    }
    .synopsis_box {
      margin-top: 15px;
      padding: 15px;
      // width: 1310.83px;
      // height: 65px;
      // left: 538.91px;
      // top: 272.28px;

      background: #f7f7f7;
      display: flex;
      .synopsis {
        flex-shrink: 0;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #595959;
      }
      .synopsis_content {
        flex: 1;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #333333;

        .more {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4e93fb;
        }
      }
    }
  }
}
.bottom_card {
  .relevance_box {
    display: flex;
    justify-content: space-between;
    .relevance {
    }
    .right {
      img {
        width: 20px;
        vertical-align: middle;
        margin-right: 5px;
      }
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      // line-height: 20px;
      color: #595959;
    }
  }

  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .office {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4e93fb;
    margin-right: 10px;
  }
}
</style>
